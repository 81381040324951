// Foundation JavaScript
// Documentation can be found at: http://foundation.zurb.com/docs
jQuery(document).foundation();
__foundationRun = true;

function foundationUpdate(el) {
	if (__foundationRun) {
		if (el.data('zfPlugin'))
			// existing element already has a plugin - update
			Foundation.reInit(el);
		else
			// new element - initialize
			el.foundation();
	}
	// else leave for foundation to init
}

//
// Document Ready
//
(function ($) {
	//
	// Generales
	//
	var ventana = $(window).width();

	$('.phone-menu').on('click', function () {
		$('.menu-full-div').fadeToggle();
	});

	$('.lupa-menu').on('click', function () {
		$('.menu-full-div-02').fadeToggle();
	});

	$('.menu-icon').on('click', function () {
		// $('.menu-full-div-03').fadeToggle();
		$('.menu-full-div-03').addClass('form-show');
	});

	$('.cerrar-contacto').on('click', function () {
		$('.menu-full-div').fadeToggle();
	});

	$('.cerrar-contacto-02').on('click', function () {
		$('.menu-full-div-02').fadeToggle();
	});

	$('.cerrar-contacto-03').on('click', function () {
		// $('.menu-full-div-03').fadeToggle();
		$('.menu-full-div-03').removeClass('form-show');
	});

	$('.cerrar-rental').on('click', function () {
		$('.contact-full-div').fadeToggle();
	});

	$('.form-uno-label').on('click', function () {
		$('.form-uno').addClass('form-show');
	});

	$('.form-dos-label').on('click', function () {
		$('.form-dos').addClass('form-show');
	});

	$('.cerrar-form').on('click', function () {
		$('.form-uno').removeClass('form-show');
		$('.form-dos').removeClass('form-show');
	});

	// $('.mitad a').text('Book');

	if ($('#sticky').length) {
		configure_fixed_menu();
	}

	$('#ver-telefono, #ver-telefono-small').on('click', function () {
		$('.ver-telefono').fadeToggle();
	});

	changeActiveMenuItem(window.location.href);
	$('.menu-item').on('click', function () {
		changeActiveMenuItem(window.location.href);
	});

	$('.tour-tabs').on('change.zf.tabs', function () {
		if ($('#iframemap').length) {
			if ($('#panel6:visible').length) {
				document.getElementById('iframemap').src += '';
			}
		}
	});  // Reload Iframe in Tabs

	$('.getaway-tabs').on('change.zf.tabs', function () {
		if ($('#iframemap').length) {
			if ($('#panel6:visible').length) {
				document.getElementById('iframemap').src += '';
			}
		}
	}); // Reload Iframe in Tabs

	$('.road-tabs').on('change.zf.tabs', function () {
		if ($('.mapa-div').length) {
			if ($('#panel6:visible').length) {
				document.getElementById('iframemap').src += '';
			}
		}
	}); // Reload Iframe in Tabs

	$('[data-toggle]').on('click', function () {
		$(document).trigger('click.zf.trigger', '[data-toggle]');
	}); // Para que el Dropdown funcione en IOS

	initializeBookingCalendars();

	$(".cb").on('change', function () {
		$(".cb").not(this).prop('checked', false);
	});

	//
	// Instagram Feed
	//
	$.instagramFeed({
		'username': 'thetravelingbeetle',
		'container': "#instagram-feed",
		'display_profile': true,
		'display_biography': false,
		'display_gallery': true,
		'get_raw_json': false,
		'callback': null,
		'styling': false,
		'items': 4,
		'items_per_row': 2,
		'margin': 1
	});

	//
	// Swiper
	//
	var mySwiper = new Swiper('#swiper-home', {
		// Optional parameters
		loop: true,

		// If we need pagination
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},

		// Navigation arrows
		// navigation: {
		// 	nextEl: '.swiper-button-next',
		// 	prevEl: '.swiper-button-prev',
		// },

		autoplay: {
			delay: 5000,
		},
	});

	var carSwiper = new Swiper('#swiper-car', {
		// Optional parameters
		loop: true,
		slidesPerView: 1.2,
		spaceBetween: 32,
		// slidesOffsetBefore: 400,
		preloadImages: true,

		// If we need pagination
		// pagination: {
		// 	el: '.swiper-pagination',
		// 	clickable: true,
		// },

		// Navigation arrows
		// navigation: {
		// 	nextEl: '.swiper-button-next',
		// 	prevEl: '.swiper-button-prev',
		// },
		breakpoints: {
			// when window width is >= 640px
			640: {
				slidesPerView: 1.2,
			},
			// when window width is >= 769px
			769: {
				slidesPerView: 2.2,
			},
			// when window width is >= 1025px
			1025: {
				slidesPerView: 3.2,
			}
		}
	});

	var tourSwiper = new Swiper('#swiper-tour', {
		// Optional parameters
		loop: true,
		slidesPerView: 1.2,
		spaceBetween: 32,
		// slidesOffsetBefore: 400,
		preloadImages: true,

		autoplay: false,

		// If we need pagination
		// pagination: {
		// 	el: '.swiper-pagination',
		// 	clickable: true,
		// },

		// Navigation arrows
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			// when window width is >= 640px
			640: {
				slidesPerView: 1.2,
			},
			// when window width is >= 769px
			769: {
				slidesPerView: 2.2,
			},
			// when window width is >= 1025px
			1025: {
				slidesPerView: 2.8,
			}
		}
	});

	var setWrapperStyles = function (swiperCenter) {
		var wrapperCenterClass = 'swiper-wrapper-center';
		var buttonDisabledClass = reviewsSwiper.params.navigation.disabledClass;
		var wrapperEl = reviewsSwiper.$wrapperEl[0];

		if (reviewsSwiper.navigation.prevEl.classList.contains('swiper-button-disabled') && reviewsSwiper.navigation.prevEl.classList.contains('swiper-button-disabled')) {
			// center slides
			wrapperEl.classList.add(wrapperCenterClass);
		} else {
			wrapperEl.classList.remove(wrapperCenterClass);
		}
	};

	var reviewsSwiper = new Swiper('#swiper-reviews', {
		// Optional parameters
		loop: false,
		slidesPerView: 1,
		spaceBetween: 32,
		// slidesOffsetBefore: 400,
		preloadImages: true,

		autoplay: false,

		// If we need pagination
		// pagination: {
		// 	el: '.swiper-pagination',
		// 	clickable: true,
		// },

		// Navigation arrows
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			// when window width is >= 640px
			640: {
				slidesPerView: 1,
				spaceBetween: 32,
			},
			// when window width is >= 769px
			769: {
				slidesPerView: 2,
				spaceBetween: 32,
			},
			// when window width is >= 1025px
			1025: {
				slidesPerView: 3,
				spaceBetween: 32,
				on: {
					init: function (swiperCenter) {
						console.log('swiper initialized');
						setTimeout(function () {
							setWrapperStyles(swiperCenter);
						}, 200);
					},
					resize: function (setWrapperStyles) {
						setWrapperStyles(swiperCenter);
					}
				},
			}
		},
	});

	$('.testimonial-gallery').featherlightGallery({
		gallery: {
			next: 'next »',
			previous: '« previous'
		},
	});

	$(window).load(function () {
	});
})(jQuery);

// //////////////////////////////////////////////////////    END DOCUMENT READY

//
// Functions
//
(function ($) {
	$(function () {
		$('.video-cont a').asyncml();
		$('.video-container a').asyncml();
	});
})(jQuery);

// function sticky_reflow() {
// 	var sticky_div = jQuery('#sticky');
// 	var window_top = jQuery(window).scrollTop();
// 	var div_top = jQuery('#sticky-prev').offset().top;
// 	var divs = jQuery('#div-1, #div-2, #div-3');
// 	var logo = jQuery('.logo-sticky');
// 	var logo_small = jQuery('.logo-sticky-small');
// 	var icon_menu = jQuery('.icon-menu-sticky');


// 	if(window_top >= div_top) {
// 		sticky_div.removeClass('sticky-hide');
// 		sticky_div.addClass('sticky-show');
// 		divs.removeClass('hide-div');
// 		divs.addClass('show-div');
// 		logo.removeClass('hide-logo');
// 		logo.addClass('show-logo');
// 		logo_small.removeClass('hide-logo');
// 		logo_small.addClass('show-logo');
// 		icon_menu.removeClass('hide-logo');
// 		icon_menu.addClass('show-logo');
// 	} else {
// 		divs.removeClass('show-div');
// 		divs.addClass('hide-div');
// 		sticky_div.removeClass('sticky-show');
// 		sticky_div.addClass('sticky-hide');            
// 		logo.removeClass('show-logo');
// 		logo.addClass('hide-logo');
// 		logo_small.removeClass('show-logo');
// 		logo_small.addClass('hide-logo');
// 		icon_menu.removeClass('show-logo');
// 		icon_menu.addClass('hide-logo');
// 	}

// 	if (window_top >= div_top) {
// 		jQuery('#sticky-replace').removeClass('sticky-hide');
// 		sticky_div.css({position:'fixed', top:'0px'}).addClass('fixed');
// 	} else {
// 		jQuery('#sticky-replace').addClass('sticky-hide');
// 		sticky_div.css('position','').css('top','').removeClass('fixed');
// 	}
// }

function changeActiveMenuItem(pageurl) {
	var pathname = window.location.pathname;
	var tpath = pathname.substring(1);
	var menupath = tpath;
	if (menupath == "") {

	} else {
		jQuery('.menu-principal').find('.active').removeClass('active');
		var page = pageurl.substring(pageurl.indexOf(window.location.host));
		page = page.substring(page.indexOf('/'));
		var menupage;
		if ('/' === page) {
			menupage = '';
		} else {
			var tpage = page.substring(1);
			menupage = tpage;
		}
		if ((menupath !== 'es' && menupage !== 'es') && (menupath !== 'es/' && menupage !== 'es/') && menupath == menupage && '' !== menupage) {
			var protocol = window.location.protocol + '//' + window.location.host + '/' + menupath;
			var toSelect = jQuery('.menu-principal').children(':has(a[href*="' + protocol + '"])');
			toSelect.addClass('active');
		} else {
			jQuery('.menu-principal').find('.active').removeClass('active');
		}
	}
}

function configure_fixed_menu() {
	var cOffset = jQuery('.container').offset();
	var eTop = cOffset.top;
	var fixedcollapsed = jQuery('.fixed-collapsed');
	var body = jQuery('body');
	var previousScroll = 0;
	var toScroll = true;
	jQuery(window).scroll(function (e) {
		var offset = eTop - jQuery(window).scrollTop();
		if (!toScroll) {
			e.preventDefault();
			return;
		}
		var currentScroll = jQuery(this).scrollTop();
		if (offset >= 0 || currentScroll == 0 || currentScroll < 0) {
			body.removeClass('collapsed');
			if (fixedcollapsed.length) {
				fixedcollapsed.addClass('fixed-hidden').removeClass('fixed-visible');
			}
		} else {
			if (offset <= 0 && (currentScroll > previousScroll)) {
				body.addClass('collapsed');
				if (fixedcollapsed.length) {
					fixedcollapsed.addClass('fixed-visible').removeClass('fixed-hidden');
				}
			} else {
				function getDocHeight() {
					var D = document;
					return Math.max(
						D.body.scrollHeight, D.documentElement.scrollHeight,
						D.body.offsetHeight, D.documentElement.offsetHeight,
						D.body.clientHeight, D.documentElement.clientHeight
					);
				}
				if (jQuery(window).scrollTop() + jQuery(window).height() < getDocHeight()) {
					if (fixedcollapsed.length) {
						fixedcollapsed.addClass('fixed-visible').removeClass('fixed-hidden');
					}
				}
			}
			previousScroll = currentScroll;
		}
	});
}

function initializeBookingCalendars() {
	if (jQuery(".datepicker-cuadrado").length) {
		jQuery(".datepicker-cuadrado").datepicker({
			dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
			showAnim: "slideDown",
			defaultDate: "+0w",
			changeMonth: false,
			numberOfMonths: 1,
			minDate: 0,
			dateFormat: "yy-mm-dd",
		});
	}
}
////////////////////////////////////////////////////////    END Functions
